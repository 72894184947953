import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Theme, WithStyles } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import createStyles from '@material-ui/core/styles/createStyles';
import withRoot from '../withRoot';
import withStyles from '@material-ui/core/styles/withStyles';
import { lightGreen } from '@material-ui/core/colors';

interface Services extends WithStyles<typeof styles> {
    classes: any;
}

type ServicesProps =
    Services
    & RouteComponentProps<{}>;

const styles = (theme: Theme) => createStyles({
                                                  root: {
                                                      display: 'flex',
                                                      flexDirection: 'column',
                                                      alignItems: 'center',
                                                      margin: '10px 20px 0 20px',
                                                      backgroundColor: lightGreen[800],
                                                  },
                                                  serviceText: {
                                                      margin: 10,
                                                      backgroundColor: 'white',
                                                      padding: 10
                                                  }
                                              });

class Services extends React.Component<ServicesProps, {}> {

    render() {
        const { classes } = this.props as ServicesProps;

        return(
            <div
                className={classes.root}
            >
                <Paper
                    style={{
                        backgroundColor: lightGreen[800]
                    }}
                >
                    <div
                        className={classes.serviceText}
                    >
                        <Typography variant={'h5'}>
                            Services
                        </Typography>
                        <div>
                            <div>

                                <p style={{margin: '5px'}}>Williams Lawn Seed SPECIALIZES in high quality Cool Season Turf,
                                    including Tall Fescue, Bluegrass, and Grey Leaf Spot resistant Ryegrass and Bentgrass
                                    BUT we also have WILDFLOWERS, Native grasses, Newer and Older  BERMUDA grasses ,  from Common
                                    SAHARA , to RIVIERA  &  YUKON…

                                    Williams Lawn Seed provides Custom Blending, Mixing and Packaging for all of your grass seed needs.
                                    Whether you are a Retail store in need of products or in the professional side of the industry like
                                    Landscaping or Ground Maintenance for a park, golf course or sports fields.

                                    Williams Lawn Seed provides Custom Blending, Mixing and Packaging for all of your grass seed needs.
                                    Whether you are a Retail store in need of products or in the professional side of the industry like
                                    Landscaping or Ground Maintenance for a park, golf course or sports fields.
                                </p>

                                <div>
                                    <ul>
                                        <li>
                                            We can package goods in 2lb, 5lb, 10lb, 25lb and 50lbs packages and 1000lb plus Totes
                                        </li>

                                        <li>
                                            Full service blending and mixing
                                        </li>

                                        <li>
                                            Specialize in cool season grass
                                        </li>
                                        <li>
                                            Customized Tags, Logos, Packaging
                                        </li>
                                        <li>
                                            Small Minimums  (Starting at 500 lbs)
                                        </li>
                                        <li>
                                            Can LTL to Full truck loads and  Local delivery.
                                        </li>
                                        <li>
                                            Specialize in Golf Courses Athletic Fields, Sod Farms
                                        </li>
                                        <li>
                                            Custom Mixes at request
                                        </li>
                                        <li>
                                            Game Mixes, Horse and Beef
                                        </li>
                                        <li>
                                            Also have Erosion Mulch and Fertilizer and Organic Fertilizer
                                        </li>
                                    </ul>

                                </div>

                            </div>
                        </div>

                    </div>

                </Paper>
            </div>
        );
    }

}

export default withRoot(withStyles(styles) (Services));