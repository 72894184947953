import * as React from 'react';
import { BrowserRouter as Router, Route, RouteComponentProps } from 'react-router-dom';
import Layout from './pages/Layout';
import home from './pages/home';
import Products from './pages/Products';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';

type AppProps =
    & RouteComponentProps<{}>;

class App extends React.Component<AppProps, {}> {
    public render() {
        return (
            <Router>
                <Layout>
                    <Route exact path={'/'} component={home}/>
                    <Route path={'/products'} component={Products}/>
                    <Route path={'/aboutus'} component={AboutUs} />
                    <Route path={'/Services'} component={Services}/>
                </Layout>
            </Router>
        );
    }
}

export default (App) as any;
