import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import * as grass from '../images/grass_dark.jpg';
import * as locationInset from '../images/featured_locations/JC_inset_129.gif';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { RouteComponentProps } from 'react-router';
import { lightGreen } from '@material-ui/core/colors';

// import * as headerImage from '../images/wls_logo_new_small.png';
interface Home extends WithStyles<typeof styles> {
  classes: any;
}

type HomeProps =
    Home
    & RouteComponentProps<{}>;

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px 20px 0 20px',
    height: '100%',

  },
  heroBanner: {
    backgroundImage: `url(${grass})`,
    flexGrow: 1
  },
  heroContent: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-around',
  },
  heroButton: {
    padding: '12px',
    margin: '20px',
    backgroundColor: 'rgba(104,159,56,.6)',
    borderRadius: '15px',
    color: 'white'
  },
  insetImage: {
    display: 'flex',
    marginRight: '15px',
    float: 'left'
  },
  insetText: {
    marginTop: 40,
    maxWidth: '75%',
    backgroundColor: lightGreen[800],
    padding: 10,
    color: 'white'
  },
  '@media screen and (max-width: 768px)': {
    insetImage: {
      display: 'none'
    },
  }
});

class Home extends React.Component<HomeProps, {}> {
  render() {
    const { classes } = this.props as HomeProps;
    return (
      <div className={this.props.classes.root}>
        <Paper
          style={{
            width: '100%'
          }}
        >
          <div
              className={classes.heroBanner}
          >
            <div style={{display: 'flex', justifyContent: 'center', padding: '30px'}}>
                  <span style={{color: 'white', fontFamily: 'Andalus', fontSize: '3em', fontStyle: 'italic'}}>
                        Providing nothing but the best for over 80 years!
                  </span>
            </div>
            <div
              className={classes.heroContent}
            >
              <Button
                  className={classes.heroButton}
                  onClick={() => this.props.history.push('/services')}
              >
                Services
              </Button>
              <Button
                  className={classes.heroButton}
                  onClick={() => this.props.history.push('/products')}
              >
                Products
              </Button>
              <Button
                  className={classes.heroButton}
                  onClick={() => this.props.history.push('/aboutus')}
              >
                About Us
              </Button>
            </div>
          </div>
        </Paper>
        <Paper
         className={classes.insetText}
        >
          <p>Williams Lawn Seed is a Midwest regional wholesale lawn seed distributor serving 10 states, and specializing in quality lawn seed and turf care products.</p>
          <div
            className={classes.insetImage}
          >
            <img src={locationInset}/>
          </div>
          <p>We offer certified & sod blends, small retail & bulk packaging, prompt delivery, and have been dedicated to quality service for over 80 years.</p>
          <p>
            Our company serves athletic fields, sod producers, golf courses, retail stores, landscape contractors, and the professional lawn care industry.
            We take pride in the fact that we've supplied grass seed to golf course played during the PGA tours, professional and college football stadiums.
            Our products are also in prominent retail stores located throughout the Midwest, so homeowners can enjoy having our quality seed on their own lawns.
            With services ranging from Custom Blending and Custom Mixing to Custom Bulk and Custom Retail packaging, Williams Lawn Seed is ready service all of your bulk grass seed needs.

            Our central location in Maryville, MO allows us to deliver products to many regions in a timely manner. Our local, family
            ownership holds the company to a higher standard when it comes to customer service and relationships. We enjoy getting to know more about the communities,
            families and facilities we serve, and consider it an honor to be invited for tours. The experts at Williams Lawn Seed are always happy to consult about the best options for each and every need.

          </p>
        </Paper>
      </div>
    );
  }
}

export default withRoot(withStyles(styles) (Home));