import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import axios, { AxiosResponse } from 'axios';
import { Tabs, Theme } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import createStyles from '@material-ui/core/styles/createStyles';
import Dialog from '@material-ui/core/Dialog';
import { lightGreen } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import { RouteComponentProps } from 'react-router';

interface Products extends WithStyles<typeof styles> {
    classes: any;
}

type ProductProps =
    Products
    & RouteComponentProps<{}>;

const styles = (theme: Theme) => createStyles({
                                                   root: {

                                                   },
                                                   tabContainer: {
                                                       // margin: '0 20px 0 20px',
                                                       // padding: '10px',
                                                       display: 'flex',
                                                       flexFlow: 'row wrap',
                                                       justifyContent: 'flex-start'
                                                   },
                                                   tabContent: {
                                                    //    margin: '0 20px 0 20px',
                                                       margin: '0 auto', 
                                                       padding: '10px',
                                                       display: 'flex',
                                                       flexFlow: 'row wrap',
                                                       justifyContent: 'flex-start'
                                                   },
                                                   cardHeader: {
                                                    //    maxWidth: 400
                                                    width: 300,
                                                    margin: '0 auto',                                                     
                                                   },
                                                  dialogContent: {
                                                      display: 'flex',
                                                      padding: 10,
                                                      backgroundColor: lightGreen[100]
                                                  },
                                                   '@media screen and (max-width: 768px)': {
                                                       tabContainer: {
                                                           margin: 0,
                                                           padding: 0,
                                                           width: '100%',
                                                           display: 'flex',
                                                           flexFlow: 'row wrap',
                                                           justifyContent: 'center'
                                                       },
                                                       tabContent: {
                                                           display: 'flex',
                                                           flexFlow: 'row wrap',
                                                           justifyContent: 'flex-start'
                                                       },
                                                       dialogContent: {
                                                           display: 'block',
                                                           padding: 10,
                                                           backgroundColor: lightGreen[100]
                                                       }
                                                   }
                                              });

class Products extends React.Component<ProductProps, {}> {

    state = {
        productList: [],
        typeList: [],
        selectedTab: '',
        dialogOpen: false,
        selectedItem: {
            title: '',
            type: '',
            shortDesc: '',
            longDesc: '',
            image: '',
            ingredients: []
        }
    };

    componentWillMount() {
        this.fetchData();

    }

    handleSelect = (event: any) => {
        let f = this.state.productList[event.currentTarget.id];
        this.setState({selectedItem: f, dialogOpen: true});
    };

    fetchData = () => {
        const self = this;
        axios({
                 // headers: [{'Content-Type': 'application/x-www-form-urlencoded'}],
                  method: 'get',
                  url: 'https://knv7atr5oe.execute-api.us-east-1.amazonaws.com/default/fetchData'
              })
            .then(function(response: AxiosResponse) {
                let typeList = new Set(response.data.map((product: any) => {{
                    return(product.type);
                }}));
                self.setState({productList: response.data, typeList: Array.from(typeList), selectedTab: 0});
            });
    };

    handleTabChange = (event: any, value: string) => {
        this.setState({selectedTab: value});
    };

    renderTab = () => {
        const {classes} = this.props as Products;
        return(
            <div
               className={classes.tabContainer}
            >
                {this.state.productList.map((product: any, index: any) => {{
                    if (product.type === this.state.typeList[this.state.selectedTab]) {
                        return(
                            <div
                                key={'product' + product.title}
                                id={index}
                                className={classes.tabContent}
                                onClick={this.handleSelect}
                            >
                                <Card
                                    className={classes.cardHeader}
                                >
                                    <div
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    >
                                        <CardHeader title={product.title} />
                                    </div>
                                    <CardContent>
                                        <div
                                            style={{
                                                // margin: 5
                                                textAlign: 'center'
                                            }}
                                        >
                                            <img
                                                src={product.image}
                                            />
                                        </div>
                                        <ul key={'list' + product.title}>
                                            {product.ingredients.map((ingredient: any, i: number) => {
                                                {
                                                    return (
                                                        <li key={i}>{ingredient}</li>
                                                    );
                                                }
                                            })}
                                        </ul>
                                    </CardContent>
                                </Card>
                            </div>
                        );
                    }
                    return('');
                }})}
            </div>
        );
    };

    render() {
        const {classes} = this.props as Products;
        return (
            <div className={classes.root}>
                <Tabs
                    value={this.state.selectedTab}
                    onChange={this.handleTabChange}
                    variant={'scrollable'}
                    scrollButtons={'auto'}
                >
                    {this.state.typeList.map((typeName: string, index: number) => {
                        return(
                            <Tab label={typeName} key={'TAB_' + index} value={index} />
                        );
                    })}
                </Tabs>
                {this.renderTab()}

                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.setState({dialogOpen: false})}

                >
                    <div
                        className={classes.dialogContent}
                    >
                        <div style={{marginTop: '20px'}}>
                            <img src={this.state.selectedItem.image}  />
                        </div>

                        <div >

                            <Typography
                                variant={'body1'}
                                style={{
                                    margin: 10
                                }}
                            >
                                {this.state.selectedItem.shortDesc}
                            </Typography>
                            <ul>
                                {this.state.selectedItem.ingredients.map((ingredient, i) => {{
                                    return(
                                        <li key={i}>{ingredient}</li>
                                    );
                                }})}
                            </ul>
                            <Typography
                                variant={'body1'}
                                style={{
                                    margin: 10
                                }}
                            >
                                {this.state.selectedItem.longDesc}
                            </Typography>

                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default withRoot(withStyles(styles) (Products));