import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Theme, WithStyles } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import createStyles from '@material-ui/core/styles/createStyles';
import withRoot from '../withRoot';
import withStyles from '@material-ui/core/styles/withStyles';
import { lightGreen } from '@material-ui/core/colors';
import image1 from '../images/about_us/garvinpic.gif';
import image2 from '../images/about_us/garvinpic2.gif';
interface AboutUs extends WithStyles<typeof styles> {
    classes: any;
}

type AboutUsProps =
    AboutUs
    & RouteComponentProps<{}>;

const styles = (theme: Theme) => createStyles({
                                                  root: {
                                                      display: 'flex',
                                                      flexDirection: 'column',
                                                      alignItems: 'center',
                                                      margin: '10px 20px 0 20px',
                                                      backgroundColor: lightGreen[800],
                                                  },
                                                  serviceText: {
                                                      margin: 10,
                                                      backgroundColor: 'white',
                                                      padding: 10
                                                  }
                                              });

class AboutUs extends React.Component<AboutUsProps, {}> {

    render() {
        const { classes } = this.props as AboutUsProps;

        return(
            <div
                className={classes.root}
            >
                <Paper
                    style={{
                        backgroundColor: lightGreen[800]
                    }}
                >
                    <div
                        className={classes.serviceText}
                    >
                        <Typography variant={'h5'}>
                            About Us
                        </Typography>
                        <div>
                            <img src={image1} style={{margin: '35px'}}/>
                            <img src={image2} style={{margin: '35px'}}/>
                        </div>

                        <p>History: Our founder, Garvin R. Williams (1913-2003) began in the lawn seed business by swinging a hand bluegrass stripper
                            at the age of 17 years, and selling the seed at $0.04 per pound. In 1930 he became a bluegrass entrepreneur. During a 10 day summer
                            harvesting period at Coffey, Missouri he purchased 20,000 pounds of hand-stripped seed that had been done by other men, women, and children.
                            A hand-stripper that had been used, along with a write-up of that story, can be found at the National Agriculture Hall of Fame at Bonner Springs, Kansas.</p>
                        <p>Methods of seed gatherings gradually changed. The yields in harvest fields began to decrease in the four Midwestern states. Now, most lawn grass
                            seed is grown in Oregon, Washington, and Idaho, according to varying needs in different areas. WLS sells this seed to golf courses, sod farms, nurseries, and retail stores.</p>

                    </div>

                </Paper>
            </div>
        );
    }

}

export default withRoot(withStyles(styles) (AboutUs));