import * as React from 'react';
import { compose } from 'recompose';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import withRoot from '../withRoot';
import { CssBaseline } from '@material-ui/core';
import Header from '../components/Header';
import createStyles from '@material-ui/core/styles/createStyles';
import { lightGreen } from '@material-ui/core/colors';

interface Layout extends WithStyles<typeof styles> {
    classes: any;
    children: Element;
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        // margin: '0 auto',
        height: '100vh',
        // width: '100vw',
        justifyContent: 'flex-start',
        backgroundColor: lightGreen[200]

    },
    childRoot: {
        marginTop: 110,
        // height: '100%',
        width: '100%',
        backgroundColor: lightGreen[200]

    }
});

type LayoutProps =
    Layout
    & WithStyles<'root'>;

class Layout extends React.Component<LayoutProps> {

    componentWillMount() {
        document.title = 'Williams Lawn Seed';

    }

    public render() {
        const {classes} = this.props as any;
        return (
            <div>
                <CssBaseline />
                <div className={classes.root}>
                    <Header />
                    <div className={classes.childRoot}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(withStyles(styles), withRouter, withRoot) (Layout) as any;